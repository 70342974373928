import { isElementNavItem } from '@vakantiesnl/components/src/utils/contentfulMenuHelpers';
import { message } from '@vakantiesnl/components/src/utils/message';
import { errorHandler } from '@vakantiesnl/services/src/util/errorHandling';
import {
	VaknlHeaderBlock,
	Partner,
	VaknlRawHeaderBlock,
	RawPartner,
	RawUsp,
	Usp,
	RawLink,
	Link,
	MicroCopy,
	FooterBlock,
	RawFooterBlock,
	RawSeo,
	Seo,
	RawTemplateHomepage,
	TemplateHomepage,
	RichTextElement,
	RichTextElementMap,
	BrandedSearchPageCollection,
	RawBrandedSearchPageCollection,
	RawBrandedSearchPageTemplate,
	RawAlertElement,
	AlertElement,
	RawContentTemplate,
	ContentTemplate,
	RawSpanElement,
	SpanElement,
	RawUnorderedList,
	UnorderedListType,
	HeaderWithTick,
	RawHeaderWithTick,
	RawSimpleTable,
	SimpleTableElement,
	RawButtonElement,
	ButtonElement,
	RawButtonRow,
	ButtonRow,
	RawSearchTemplate,
	SearchTemplate,
	RawImageGrid,
	ImageGrid,
	RawImageCard,
	ImageCard,
	RawSearchBlock,
	SearchBlock,
	LinkCard,
	RawImageLink,
	ImageLink,
	ZooverRawSearchTemplate,
	ZooverSearchTemplate,
	MappedImageAsset,
	NewButtonElement,
	RawNewButtonElement,
	MenuLink,
	ElementNavItem,
	RawLinkList,
	RawElementNavItem,
	LinkList,
	RawNavigationItem,
} from '@vakantiesnl/types';
import { Asset, Entry, EntryCollection } from 'contentful';

import { asValueOrNull } from '../util/asValueOrNull';

type UnknownEntry = Entry<unknown>;

// Filters the include entries from the entry collection response and sorts it based on the matchingIds => sys.id
export const filterIncludes = <T>(data: EntryCollection<unknown>, matchingIds: string[]): T[] =>
	data.includes.Entry.filter((item: UnknownEntry) => matchingIds.includes(item.sys.id)).sort(
		(a: UnknownEntry, b: UnknownEntry) => matchingIds.indexOf(a.sys.id) - matchingIds.indexOf(b.sys.id),
	);

export const mapMedia = (media: Asset): Asset => ({
	...media,
	fields: {
		...media.fields,
		file: {
			...media.fields.file,
			fileName: media.fields.file.fileName,
			url: media.fields.file.url,
		},
	},
});

export const mapPartner = ({ fields }: RawPartner): Partner => ({
	title: fields.title,
	href: fields.link,
	imgSrc: fields.image.fields.file.url,
	imgHoverSrc: fields.hoverImage.fields.file.url,
});

export const getTarget = (url?: string): '_self' | '_blank' => (url && url.startsWith('/') ? '_self' : '_blank');

export const mapLink = ({ fields }: RawLink): Link | undefined => {
	let link = fields.link ? fields.link : fields.page?.fields?.slug;

	if (!link) {
		return undefined;
	}
	if (fields.page?.fields?.queryParams) {
		link += fields.page?.fields?.queryParams;
	}

	return {
		title: fields.title,
		link,
		target: fields.isExternal ? '_blank' : '_self',
		isExternal: fields.isExternal,
		iconType: fields.iconType,
	};
};

export const mapUsp = ({ fields }: RawUsp): Usp => ({
	title: fields.title,
	subtitle: fields.subtitle ? fields.subtitle : undefined,
	image: fields.image ? mapMedia(fields.image) : undefined,
	type: fields.type,
});

export const mapHeaderBlock = (data: EntryCollection<VaknlRawHeaderBlock>): VaknlHeaderBlock => {
	const uspsIds = data.items[0].fields.usps.map((item) => item.sys.id);
	const partnerIds = data.items[0].fields.partners.map((item) => item.sys.id);
	const paymentPartnerIds = data.items[0].fields.paymentPartners.map((item) => item.sys.id);
	const menuItemsIds = data.items[0].fields.menuItems.map((item) => item.sys.id);
	const subMenuItemsIds = data.items[0].fields.subMenuItems.map((item) => item.sys.id);

	return {
		title: data.items[0].fields.title,
		paymentPartners: filterIncludes<RawPartner>(data, paymentPartnerIds).map(mapPartner),
		partners: filterIncludes<RawPartner>(data, partnerIds).map(mapPartner),
		usps: filterIncludes<RawUsp>(data, uspsIds).map(mapUsp),
		menuItems: filterIncludes<RawLink>(data, menuItemsIds)
			.map(mapLink)
			.filter((item) => item !== undefined) as Link[],
		subMenuItems: filterIncludes<RawLink>(data, subMenuItemsIds)
			.map(mapLink)
			.filter((item) => item !== undefined) as Link[],
	};
};

/** Temporary editing of the metadata description about booking costs.
 * To be solved on the long term in https://vakanties.atlassian.net/browse/WEB-4935
 */
const mapSeoDescription = (description: string): string => {
	const newText = process.env.NEXT_PUBLIC_BRAND === 'ZVRNL' ? 'Laagste prijsgarantie' : 'ANVR en SGR zekerheid';
	return description.replace(/geen boekingskosten|géén boekingskosten/gi, newText);
};

export const mapSeo = (data: RawSeo): Seo => ({
	title: data.title,
	description: mapSeoDescription(data.description),
	image: data.image ? data.image.fields.file.url : null,
	robots: data.robots,
	canonical: data.canonical,
	pageType: data.pageType,
	slug: data.slug,
	queryParams: asValueOrNull(data.queryParams),
	gtmPageType: data.gtmPageType,
	preload: asValueOrNull(data.preload),
});

export const mapSearchBlock = (data: RawSearchBlock): SearchBlock => ({
	title: data.title,
	suggestion: data.suggestion,
	cta: mapButtonElement(data.cta.fields),
});

export const mapLinkCard = (data: RawBrandedSearchPageTemplate): LinkCard => {
	return {
		title: data.fields.title,
		imgSrc: data.fields.headerImage?.fields && data.fields.headerImage.fields.file.url,
		cardImage: data.fields.cardImage?.fields && data.fields.cardImage.fields.file.url,
		verticalImgSrc: data.fields.verticalImage?.fields && data.fields.verticalImage.fields.file.url,
		target: '_self',
		iconName: data.fields.iconType,
		queryParams: data.fields.seo.fields.queryParams,
		slug: data.fields.seo.fields.slug,
	};
};

/** Only map cards when they have an image */
export const mapBrandedSearchCards = (data: RawBrandedSearchPageTemplate[]): LinkCard[] =>
	data
		.filter(
			(data: RawBrandedSearchPageTemplate) =>
				data.fields?.seo?.fields?.slug && (data.fields?.headerImage?.fields || data.fields?.cardImage?.fields),
		)
		.map((data: RawBrandedSearchPageTemplate) => mapLinkCard(data));

export const mapBrandedSearchPageCollection = (data: RawBrandedSearchPageCollection): BrandedSearchPageCollection => ({
	title: data.fields.title,
	brandedsearchpages: mapBrandedSearchCards(data.fields.brandedsearchpages),
});

export const mapBrandedSearchPageCollections = (
	data: RawBrandedSearchPageCollection[],
): BrandedSearchPageCollection[] => data.map((data) => mapBrandedSearchPageCollection(data));

export const mapHomepageTemplate = (data: EntryCollection<RawTemplateHomepage>): TemplateHomepage => {
	const { seo, headerImage, uspsTitle, usps, brandedsearchpages, brandedsearchpagesHeading, searchBlock } =
		data.items[0].fields;

	return {
		brandedsearchpagesHeading,
		seo: mapSeo(seo.fields),
		headerImage: headerImage.map((item) => item.fields && item.fields.file.url) ?? null,
		uspsTitle: uspsTitle,
		usps: usps.map(mapUsp),
		brandedsearchpages: mapBrandedSearchPageCollections(brandedsearchpages),
		id: data.items[0].sys.id,
		searchBlock: mapSearchBlock(searchBlock.fields),
	};
};

export const mapContentTemplate = (
	data: EntryCollection<RawContentTemplate>,
	dynamicContent?: string,
): ContentTemplate => {
	const { content, seo, title, wrapperVariant, headerImage } = data.items[0].fields;

	let extendedTitle = title;
	/** Handle dynamic titles. E.g. "Boeking {bookingNumber} to Boeking 1234" */
	if (!!dynamicContent && title.includes('bookingNumber')) {
		extendedTitle = message(title, { bookingNumber: dynamicContent });
		seo.fields.slug = `${seo.fields.slug}/${dynamicContent}`;
	}

	return {
		seo: mapSeo(seo.fields),
		title: extendedTitle,
		content,
		wrapperVariant,
		id: data.items[0].sys.id,
		headerImage: mapImageAsset(headerImage),
	};
};

const _mapBrandedSearchTemplate = (data: EntryCollection<RawSearchTemplate>): SearchTemplate => {
	const {
		seo,
		title,
		subtitle,
		brandedSearchPages,
		usps,
		imageText,
		readMoreText,
		footerTitle,
		shortFooterDescription,
		footerImages,
		description,
		recommendations,
		footerAnchor,
	} = data.items[0].fields;

	// getServerSideProps only accepts valid JSON objects, without undefined properties.
	return {
		seo: mapSeo(seo.fields),
		title,
		subtitle,
		brandedSearchPages: brandedSearchPages && mapBrandedSearchCards(brandedSearchPages),
		usps: usps && usps.map(mapUsp),
		imageText,
		readMoreText,
		id: data.items[0].sys.id,
		footerTitle,
		shortFooterDescription,
		footerImages: footerImages?.map((image) => image?.fields && mapMedia(image)),
		description,
		recommendations,
		footerAnchor,
	};
};

export function mapBrandedSearchTemplate(
	data: EntryCollection<ZooverRawSearchTemplate | RawSearchTemplate>,
): ZooverSearchTemplate | SearchTemplate {
	const mappedBrandedSearchTemplate = _mapBrandedSearchTemplate(data);
	const fields = data.items[0].fields;

	if (isZooverSearchTemplate(fields)) {
		const { headerImage, shortHeaderDescription, cardImage } = fields;

		const zooverSearchTemplate: ZooverSearchTemplate = {
			...mappedBrandedSearchTemplate,
			headerImage: headerImage?.fields && {
				url: headerImage.fields.file.url,
				title: headerImage.fields.title,
			},
			cardImage: cardImage?.fields && {
				url: cardImage.fields.file.url,
				title: cardImage.fields.title,
			},
			shortHeaderDescription,
		};

		return zooverSearchTemplate;
	}

	return mappedBrandedSearchTemplate;
}

export function isZooverSearchTemplate(data: RawSearchTemplate): data is ZooverRawSearchTemplate;
export function isZooverSearchTemplate(
	data: RawSearchTemplate | SearchTemplate,
): data is ZooverRawSearchTemplate | ZooverSearchTemplate {
	return 'shortHeaderDescription' in data;
}

export const mapFooterBlock = (data: EntryCollection<RawFooterBlock>): FooterBlock => {
	const qualityMarksIds = data.items[0].fields.qualityMarks.map((item) => item.sys.id);
	const paymentMethodsIds = data.items[0].fields.paymentMethods.map((item) => item.sys.id);
	const otherPartnersIds = data.items[0].fields.otherPartners.map((item) => item.sys.id);

	return {
		contactTitle: data.items[0].fields.contactTitle,
		contactLinks: data.items[0].fields.contactLinks
			.map(mapImageLink)
			.filter((item) => item !== undefined) as ImageLink[],
		usps: data.items[0].fields.usps.map(mapUsp),
		logo: data.items[0].fields.logo,
		qualityMarks: filterIncludes<RawPartner>(data, qualityMarksIds).map(mapPartner),
		paymentMethods: filterIncludes<RawPartner>(data, paymentMethodsIds).map(mapPartner),
		otherPartners: filterIncludes<RawPartner>(data, otherPartnersIds).map(mapPartner),
		navigation: data.items[0].fields.navigation.map(mapLink).filter((item) => item !== undefined) as Link[],
	};
};

export const mapRichTextElements = (data: EntryCollection<RichTextElement>): RichTextElementMap => {
	const elementMap: RichTextElementMap = {};
	data.items.forEach((entry) => {
		elementMap[entry.fields.key] = entry.fields.value;
	});

	return elementMap;
};

export const mapAirportName = (airport: { value: string; code: string }, microCopies: MicroCopy): string => {
	if (microCopies[`airport.${airport.code}`]) {
		return microCopies[`airport.${airport.code}`];
	} else {
		return airport.value;
	}
};

export const mapButtonElement = (data: RawButtonElement): ButtonElement => {
	return {
		title: data.title,
		variant: data.variant,
		color: data.color,
		link: data.link,
		iconType: data.iconType,
	};
};

export const mapNewButtonElement = (data: RawNewButtonElement): NewButtonElement => {
	return {
		title: data.title,
		variant: data.variant,
		link: data.link,
		trailingIcon: data.iconType,
	};
};

export const mapImageCard = (data: RawImageCard): ImageCard => {
	const imageUrl = data.image?.fields?.file?.url;
	const link = mapLink(data.link);
	return {
		buttonTitle: data.buttonTitle,
		imageSrc: imageUrl ? imageUrl : '',
		link: link?.link ? link?.link : '',
		variant: data.variant,
		target: link?.target || '_blank',
	};
};

export const mapButtons = (data: Array<Entry<RawButtonElement>>): ButtonElement[] =>
	data.map((entry) => mapButtonElement(entry.fields));

export const mapImageCards = (data: Array<Entry<RawImageCard>>): ImageCard[] =>
	data.map((entry) => mapImageCard(entry.fields));

export const mapAlertElement = (data: Entry<RawAlertElement>): AlertElement => {
	const fields = data.fields;
	return {
		title: fields.title,
		variant: fields.variant,
		description: fields.description,
		direction: fields.direction,
		hyperlink: fields.hyperlink && mapLink(fields.hyperlink),
	};
};

export const mapUnorderedList = (data: RawUnorderedList): UnorderedListType =>
	data.content.map(({ content }) => content.map((data) => data.content)) as UnorderedListType;

export const mapHeaderWithTickList = (data: Entry<RawHeaderWithTick>): HeaderWithTick => data.fields.headersWithTick;

export const mapSpanElement = (data: Entry<RawSpanElement>): SpanElement => {
	const fields = data.fields;
	return {
		title: fields.title,
	};
};

export const mapSimpleTableElement = (data: Entry<RawSimpleTable>): SimpleTableElement => {
	const fields = data.fields;
	return {
		title: fields.title,
		table: fields.table,
	};
};

export const mapButtonRow = (data: Entry<RawButtonRow>): ButtonRow => {
	const fields = data.fields;
	return {
		buttons: mapButtons(fields.buttons),
	};
};

export const mapImageGrid = (data: Entry<RawImageGrid>): ImageGrid => {
	const fields = data.fields;
	return {
		imageCards: fields && mapImageCards(fields.imageCards),
	};
};

export const mapImageLink = ({ fields }: RawImageLink): ImageLink | undefined => {
	if (!fields.link) return undefined;

	const imageUrl = fields.image.fields.file.url;
	const hoverImageUrl = fields.hoverImage ? fields.hoverImage.fields.file.url : undefined;

	return {
		title: fields.title,
		link: fields.link,
		image: imageUrl ? imageUrl : '',
		hoverImage: hoverImageUrl ? hoverImageUrl : '',
	};
};

export const getItemContentType = <T>(item: Entry<T>): string => item.sys.contentType.sys.id;

export const mapImageAsset = (image: Asset | undefined): MappedImageAsset | null => {
	return image?.fields?.file.details.image
		? {
				url: image.fields?.file?.url,
				title: image.fields?.title,
				width: image.fields?.file.details.image?.width,
				height: image.fields?.file.details.image?.height,
			}
		: null;
};

export const filterUserMenu = (
	rightMenuItems: Array<MenuLink | ElementNavItem>,
	microCopies: Record<string, string>,
): ElementNavItem | null => {
	const menu = rightMenuItems.find(
		(item): item is ElementNavItem => isElementNavItem(item) && item.listTitle === microCopies['userMenu.title'],
	);

	if (!menu) {
		errorHandler.report('No ElementNavItem found');
		return null;
	}

	return menu;
};

/** The first item of the navigation item list defines the types for all following items */
export const mapNavigationItems = (data: RawNavigationItem[]): Array<LinkList | Link> | undefined => {
	const firstItemType = getItemContentType<RawLinkList[] | RawLink[]>(data[0]);

	if (firstItemType === 'link') {
		const filteredItems = data.filter(
			(navItem) => navItem.sys.contentType.sys.id === firstItemType,
		) as unknown as RawLink[];
		return filteredItems.map(mapLink).filter((item) => item !== undefined) as Link[];
	}
	if (firstItemType === 'elementLinkList') {
		const filteredItems = data.filter(
			(navItem) => navItem.sys.contentType.sys.id === firstItemType,
		) as unknown as RawLinkList[];

		const filteredLinkList = filteredItems.map((item) => {
			const mappedLinkList = mapLinkList(item, false);
			/** Limit the amount of links in linklists to 5 */
			return { ...mappedLinkList, links: mappedLinkList.links.slice(0, 5) };
		});

		if (filteredLinkList.length > 1) return filteredLinkList;
	}
	return undefined;
};

/** Extending navigation item links with an addition, e.g. the booking number */
export const extendNavigationItems = (data: ElementNavItem, linkAddition: string): ElementNavItem => {
	return {
		listTitle: data.listTitle,
		navItemType: data.navItemType,
		navigationItems: data.navigationItems?.map((navItem) => {
			return 'link' in navItem ? { ...navItem, link: `${navItem.link}/${linkAddition}` } : navItem;
		}),
	};
};

export const mapMenuItem = (data: RawElementNavItem): ElementNavItem => {
	const firstItemType = getItemContentType<RawLinkList[] | RawLink[]>(data.fields.navigationItems[0]);

	const getFirstNavItemType = (itemType: string): 'link' | 'elementLinkList' | undefined => {
		if (itemType === 'link') return 'link';
		if (itemType === 'elementLinkList') return 'elementLinkList';
		return undefined;
	};

	return {
		listTitle: data.fields.listTitle,
		navItemType: getFirstNavItemType(firstItemType),
		navigationItems: mapNavigationItems(data.fields.navigationItems),
	};
};

/** Image links are excluded for the headerNav */
export const mapLinkList = (data: RawLinkList, withImageLinks = true): LinkList => {
	const { fields } = data;
	const links = (fields.links as (RawLink | RawImageLink)[]).filter((item) => !('image' in item.fields)) as RawLink[];
	const imageLinks = (fields.links as (RawLink | RawImageLink)[]).filter(
		(item) => 'image' in item.fields,
	) as RawImageLink[];

	return {
		title: fields.title,
		listTitle: fields.listTitle,
		key: fields.key,
		iconType: fields.iconType,
		links: [
			...(links.map(mapLink).filter((item) => item !== undefined) as Link[]),
			...(withImageLinks
				? (imageLinks.map(mapImageLink).filter((item) => item !== undefined) as ImageLink[])
				: ([] as Link[])),
		],
	};
};
