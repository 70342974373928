import { IncomingMessage } from 'http';
import { ParsedUrlQuery } from 'querystring';

import { DEFAULT_DURATIONS } from './durations';

export const filterContentfulSlugFromPath = (path: string): string => {
	const splitPath = path.split(/\?/);
	return splitPath[0];
};

export const buildCanonicalUrl = (url: string, key: string, value: string): string => {
	const newUrl = new URL(url);
	if (value === '1') {
		newUrl.searchParams.delete(key);
	} else {
		newUrl.searchParams.set(key, value);
	}
	return newUrl.toString();
};

export const buildUrlFromServer = (req: IncomingMessage, searchParams: URLSearchParams): string =>
	`${req?.url?.split('?')[0]}${searchParams.toString().length > 0 ? '?' : ''}${searchParams.toString()}`;

export const buildServerSearchParams = (req: IncomingMessage): URLSearchParams =>
	new URLSearchParams(req?.url?.split('?')[1]);

/**
 * Creates the query params for the branded search related cards
 * adds already existing query params from Contentful and some filters the user selected.
 * @param {string} queryParams - query params coming from Contentful.
 * @param {ParsedUrlQuery} query - query from the context containing all query params
 * @returns {string} - Returns the query params as a string.
 */
export const buildBrandedSearchRelatedCardUrlParams = (
	queryParams: string,
	query?: ParsedUrlQuery,
	departureDate?: string[] | null,
): string | undefined => {
	// We only want to add the following query params from the filters to the branded search related cards
	const queryParamsToAppend = [
		'departure_date',
		'flexible_departure_date',
		'durations',
		'airports',
		'min_price',
		'max_price',
		'mealplans',
		'facilities',
		'awards',
		'rating',
		'distances',
	];

	const queryObject = new URLSearchParams(queryParams);

	// Filter out query params we want to pass on to the branded search cards
	if (query && typeof query === 'object') {
		const queryAsArray = Object.entries(query);
		queryAsArray.forEach(([key, value]) => {
			if (value && queryParamsToAppend.includes(key)) {
				// Don't add the durations query param when it has the default value
				if (key === 'durations' && value === DEFAULT_DURATIONS) {
					return;
				}
				queryObject.append(key, value.toString());
			}
		});

		const hasQueryDepartureDate = queryObject.has('departure_date');
		const formattedDepartureDate = departureDate?.join('+');

		if (!hasQueryDepartureDate && formattedDepartureDate) {
			queryObject.append('departure_date', formattedDepartureDate);
		}
	}
	if (queryObject.toString()) {
		return `?${queryObject.toString()}`;
	}
};
